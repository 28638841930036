<template>
  <div v-if="status == 1" style="display: inline-block" title="Compartir">
  <!-- <a data-fancybox data-type="iframe" data-src='{{ route('popups.info', $job->user->id) }}' href="javascript:;" class="ic ic--destaca"></a> -->
    <div
      class="ic ic--share"
      :data-tooltip-content="'#ad-share-' + jobId"
      ref="btnTooltip"
    ></div>
  </div>

    <a v-if="isCollaborator" class="ic ic--businesses on"></a> <!-- REVISAR -->
    <a v-else data-fancybox data-type="iframe"  v-if="userIs != 1" :data-src="`/popup/colaboradores?id=${ownerId}&hideImport=1`" href="javascript:;" class="ic ic--businesses" title="Añadir a empresas colaboradoras"></a>

  <a v-if="status == 1" data-fancybox data-type="iframe" :data-src="`/popup/anuncio?id=${jobId}`" class="ic ic--businesses-send" title="Escribir al anunciante"></a>
  <div v-if="userIs != 1" class="ic ic--businesses-fav" :class="{ active: state.isFavorite }" title="Guardar anuncio" @click="toggleFavorite"></div>
  <div class="tooltip_templates">
    <div class="tooltip ad-tooltip" :id="'ad-share-' + jobId">
      <nav>
        <a @click.prevent="sendEmail" target="_blank" class="ad-tooltip__link"><img class="ad-tooltip__icon" :src="icons.email" alt="Icon" />Enviar Email</a>
        <a :href="shareLinks.whatsapp" target="_blank" class="ad-tooltip__link"><img class="ad-tooltip__icon" :src="icons.whatsapp" alt="Icon"/>WhatsApp</a>
        <a :href="shareLinks.twitter" target="_blank" class="ad-tooltip__link"><img class="ad-tooltip__icon" :src="icons.twitter" alt="Icon"/>Twitter</a>
        <a :href="shareLinks.linkedin" target="_blank" class="ad-tooltip__link"><img class="ad-tooltip__icon" :src="icons.linkedin" alt="Icon"/>Linkedin</a>
        <a :href="shareLinks.facebook" target="_blank" class="ad-tooltip__link"><img class="ad-tooltip__icon" :src="icons.facebook" alt="Icon"/>Facebook</a>
      </nav>
    </div>
  </div>
</template>

<script>
import { reactive, ref } from '@vue/reactivity';
import { onMounted } from '@vue/runtime-core';
export default {
  props: {
    userId: String,
    jobId: String,
    ownerId: String,
    icons: Object,
    markFavorite: Boolean,
    userIs: String,
    status: String,
  },

  setup(props) {
    const btnTooltip = ref(null);
    const state = reactive({
      isFavorite: props.markFavorite,
      collaborator: props.collaborators,
      userIs: props.userIs,
      status: props.status,
      collaboratorIds: [],
      isCollaborator: false,
      loading: true,
    });
    const whatsappMessage = "Hola! Me interesó mucho este anuncio y creo que a ti también puede llegar a interesarte";
    const shareUrl = `${new URL(document.URL).origin}/anuncios/ver/${props.jobId}`;
    const shareLinks = {
      whatsapp: `https://wa.me/?text=${whatsappMessage}%20${shareUrl}`,
      twitter: `https://twitter.com/share?url=${shareUrl}`,
      facebook: `https://es-es.facebook.com/sharer.php?u=${shareUrl}`,
      linkedin: `https://es.linkedin.com/sharing/share-offsite/?url=${shareUrl}`
    }
    const sendEmail = () => {
      const email = '';
      const subject = 'Me interesó mucho este anuncio y creo que a ti también puede llegar a interesarte';
      const body = shareUrl;

      // Construir el enlace de correo electrónico
      const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

      // Abrir el cliente de correo electrónico predeterminado del usuario
      window.location.href = mailtoLink;
    };

    async function fetchCollaborators() {
      await axios.get(`/colaboradores_get`)
        .then(response => {
            state.collaboratorIds = response.data;
          state.isCollaborator = state.collaboratorIds.map(String).includes(props.ownerId);

        })
        .catch(error => {
          console.error(error);
        });
    }

    /* (async () => {
      await fetchCollaborators();
      initializeTooltips();
    })(); */
    onMounted(() => {
        fetchCollaborators();
        initializeTooltips();
    });



    async function toggleFavorite() {
        if(props.userId){
          let currentIsFav = state.isFavorite;
          state.isFavorite = ! state.isFavorite;
            await axios
              .get(`/anuncios/${props.jobId}/toggle-favorito`, {
                params: {
                  user_id: props.userId,
                },
              })
              .catch(error => {
                state.isFavorite = currentIsFav;
              })
        }
    }

    function collab(ownerId) {
        let collabo = state.collaborator;

     return false;

    }

    function initializeTooltips(){
      // Initialize tooltipster ad tooltip
      $(btnTooltip.value).tooltipster({
            trigger: 'click',
            contentAsHTML: true,
            interactive: true,
            animationDuration: 0,
            side: 'bottom',
            debug: false,
            theme: 'tooltipster-white',
            functionBefore: function (instance, helper) {
            var btn = helper.origin
            btn.classList.add('on')
          },
          functionAfter: function (instance, helper) {
          var btn = helper.origin
          btn.classList.remove('on')
          }
      });
    }

    return {
      state,
      btnTooltip,
      toggleFavorite,
      collab,
      shareLinks,
      sendEmail,
    }
  },
};
</script>
