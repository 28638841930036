<template>
  <div class="content">
    <div class="container container--box">
      <div class="columns is-mobile is-multiline">
        <div class="column is-12-mobile is-8-tablet">
          <h1 class="product__name">Empresas Colaboradoras</h1>
        </div>
        <div class="column is-12-mobile is-4-tablet product-header__right">
          <button type="button" class="button" data-fancybox data-src="#add-collaboration">Nueva Lista</button>
        </div>
      </div>

			<hr>

			<div  style="margin-top: 1rem; display: flex; gap: 10px;">
                <button v-if="state.collaborations.length" type="button" class="button" data-fancybox data-src="#add-collaborator">Añade una empresa</button>
                <button v-if="!hideImport" type="button" class="button" @click="showExtraCompanyModal = true">Añadir empresa externa</button>
                <button v-if="!hideImport" type="button" class="button" @click="showImportModal = true">Importar Excel</button>
            </div>


      <!-- Listas -->
			<div class="collab-list">
				<collaboration v-for="(collaboration,index) in state.collaborations"
				:key="index"
				:order="index"
				:icons="icons"
				:collaboration="collaboration"
                :collaborations="state.collaborations"
				:openAccordion="true"
				@deleteCollaboration="deleteCollaboration"
				></collaboration>
	  	    </div>
            <div v-if="state.extraCompanies.length" style="margin-top: 2rem">
                <h2 class="title-2">Empresas Externas</h2>
                <div v-for="(company, index) in state.extraCompanies"
                    :key="index"
                    class="company-item"
                    :class="{ 'disabled-company': company.send_mail === 0 }">
                    <div class="company-info">
                        <strong>{{ company.name }}</strong> - {{ company.email }} - {{ company.phone }}
                    </div>
                    <div class="company-actions">
                        <img :src="icons.edit" @click="editExtraCompany(company)">
                        <img :src="icons.trash" @click="openDeleteModal(company)">
                    </div>
                </div>
            </div>
            <br>
            * Los contactos marcados en gris han desactivado la recepción de correos.

		</div>


        <!-- Popup para añadir ExtraContact -->
    <div v-if="showExtraCompanyModal" class="modal-overlay">
      <div class="modal">
        <h2>{{ isEditing ? "Editar Empresa Extra" : "Añadir Empresa Extra" }}</h2>

        <form @submit.prevent="isEditing ? updateExtraCompany() : saveExtraCompany()">
          <label>Nombre Fiscal:</label>
          <input type="text" class="input" v-model="extraCompany.name" required />

          <label>Email:</label>
          <input type="email"  class="input" v-model="extraCompany.email" required />

          <label>Teléfono:</label>
          <input type="text" class="input" v-model="extraCompany.phone" required />

          <div class="modal-buttons">
            <button type="submit" class="button">{{ isEditing ? "Actualizar" : "Guardar" }}</button>
            <button type="button" class="button cancel" @click="closeModal">Cancelar</button>
          </div>
        </form>
      </div>
    </div>

    <div v-if="showDeleteModal" class="modal-overlay">
        <div class="modal">
            <h2>¿Estás seguro de que quieres eliminar la empresa {{ companyToDelete?.name }}?</h2>
            <div class="modal-buttons">
            <button class="button" @click="confirmDeleteExtraCompany">Eliminar</button>
            <button class="button cancel" @click="showDeleteModal = false">Cancelar</button>
            </div>
        </div>
    </div>

    <!-- Popup para importar Excel -->
    <div v-if="showImportModal" class="modal-overlay">
        <div class="modal">
            <h2>Importar Empresas desde Excel</h2>

            <form @submit.prevent="previewExcelData">
            <label>Selecciona el archivo:</label>
            <input type="file" @change="handleFileUpload" accept=".xls,.xlsx" class="input" />

            <div class="modal-buttons">
                <button type="button" class="button" @click="previewExcelData">Previsualizar</button>
                <button class="button cancel" @click="showImportModal = false">Cancelar</button>
            </div>
        </form>
        </div>
    </div>


    <!-- Modal para mapeo de columnas -->
    <div v-if="showMappingModal" class="modal-overlay">
        <div class="modal">
          <h2>Selecciona las Columnas</h2>
          <table class="styled2-table">
            <tr>
              <th>Campo</th>
              <th>Columna del Excel</th>
            </tr>
            <tr v-for="(fieldLabel, fieldKey) in systemFields" :key="fieldKey">
              <td>{{ fieldLabel }}</td>
              <td>
                <select v-model="columnMapping[fieldKey]" class="input">
                  <option v-for="(column, index) in excelHeaders" :key="index" :value="column">{{ column }}</option>
                </select>
              </td>
            </tr>
          </table>
          <br>
          <h3>Vista Previa de los Datos (primeras filas)</h3>
          <table class="styled-table">
            <thead>
              <tr>
                <th v-for="(header, index) in excelHeaders" :key="index">{{ header }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, rowIndex) in previewRows" :key="rowIndex">
                <td v-for="(cell, cellIndex) in row" :key="cellIndex">{{ cell }}</td>
              </tr>
            </tbody>
          </table>

          <div class="modal-buttons">
            <button class="button"
                    @click="importMappedData"
                    :disabled="!isAllColumnsMapped">
            Importar
            </button>
            <button class="button cancel" @click="showMappingModal = false">Cancelar</button>
          </div>
        </div>
      </div>

      <div v-if="showConfirmImportModal" class="modal-overlay">
  <div class="modal">
    <h2>Confirmar Importación</h2>
    <p>Revisa los datos antes de importarlos:</p>
    <table class="styled-table">
      <thead>
        <tr>
          <th v-for="(header, index) in Object.values(systemFields)" :key="index">{{ header }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, rowIndex) in mappedDataPreview.slice(0, 10)" :key="rowIndex">
          <td>{{ row.name }}</td>
          <td>{{ row.email }}</td>
          <td>{{ row.phone }}</td>
        </tr>
      </tbody>
    </table>
    <p v-if="mappedDataPreview.length > 5">Mostrando solo las primeras 10 filas...</p>

    <div class="modal-buttons">
      <button class="button" @click="confirmAndImportData">Confirmar e Importar</button>
      <button class="button cancel" @click="showConfirmImportModal = false">Cancelar</button>
    </div>
  </div>
</div>


		<!-- Modal nueva lista -->
		<div style="display: none; width: 780px" id="add-collaboration" ref="storeFancybox">
			<h2 class="title-2">Nombra la nueva lista...</h2>
			<div style="margin-bottom: 1rem;">
				<input type="text" ref="addCollaboration" class="input" v-on:keyup.enter="storeCollaboration">
			</div>
			<button type="button" class="button" ref="addCollaborationBtn" @click="storeCollaboration">Añadir</button>
		</div>

		<!-- Modal añadir empresa a lista -->
		<div style="display: none; width: 780px" id="add-collaborator" ref="searchFancybox">
			<h2 v-if="!isPopup" class="title-2">Busca la empresa por nombre o por CIF</h2>
			<div class="search-collaborators" style="margin-bottom: 1rem;">
				<input v-if="!isPopup" class="input search-collaborators__input" type="search" ref="searchCollaboratorsInput" v-model="state.searchCollaborator" v-on:keyup="searchCollaborators">
				<div v-if="!state.noResults">
					<div class="search-collaborators__results" ref="collaboratorsResults">
						<div class="search-item" v-for="(collaborator,index) in state.searchResults" :key="index" @click="selectCollaborator(collaborator, $event)">
							{{collaborator.company}}
						</div>
					</div>
					<div v-if="state.selectedResult?.id" style="margin-top: 1rem">
						<h4>Selecciona las listas en las que quieres añadir <span v-if="state.selectedResult?.id">a {{ state.selectedResult.company }}</span></h4>
						<div v-if="state.collaborations.length > 0" v-for="(collaboration, index) in state.collaborations" :key="index" style="margin-top: 0.3rem">
							<input type="checkbox" :id="'collaboration' + collaboration.id" @click="pushToCollaborations(collaboration.id)">
							<label :for="'collaboration' + collaboration.id">{{ collaboration.name }}</label>
                            <li style="margin-left: 15px;" v-for="collaborate in collaboration.collaborators">{{ collaborate.company }}</li>

						</div>
						<div v-else> Debes crear una lista antes de poder añadir empresas.</div>
						<div class="text-center" style="margin-top: 1rem">
							<button style="margin-top: 1rem" type="button" class="button" :disabled="state.selectedResult?.id && !state.attachToCollaborations.length" @click="attachCollaborator">Guardar selección</button>
						</div>
						<div v-if="state.error" @click="state.error=''" class="event-notice event-notice--error" style="margin-top: 1rem">{{ state.error }}</div>
					</div>
				</div>
				<div v-else>
					No se han encontrado resultados
				</div>
			</div>
		</div>
  </div>
</template>

<script>
import { computed } from "vue";
import * as XLSX from 'xlsx';
import { reactive, ref } from "@vue/reactivity";
import { nextTick } from "@vue/runtime-core";
import Collaboration from "../components/Collaboration.vue";
import { onMounted, watch } from '@vue/runtime-core';
import { SEARCH_TIMEOUT } from '../app';

export default {
  components: {
    Collaboration,
  },
  props: {
		userId: Number,
        icons: Object,
		searchUrl: String
  },
  computed: {
    currentPath() {
      return window.location.pathname;
    },
    isPopup() {
      return this.currentPath.includes("popup");
    }
  },
  setup(props) {
      /**
       * DOM Elements
       */
      const showMappingModal = ref(false);
      const showConfirmImportModal = ref(false);
      const mappedDataPreview = ref([]);
        const excelHeaders = ref([]);
        const excelData = ref([]);
        const columnMapping = reactive({});
       const showDeleteModal = ref(false);
       const companyToDelete = ref(null);
       const showImportModal = ref(false);
        const selectedFile = ref(null);
        const showExtraCompanyModal = ref(false);
        const isEditing = ref(false);
        const extraCompany = reactive({ id: null, name: "", email: "", phone: "" });
		const addCollaboration = ref(null);
		const addCollaborationBtn = ref(null);
		const collaboratorsResults = ref(null);
		const storeFancybox = ref(null);
		const searchCollaboratorsInput = ref(null);
         const previewRows = ref([]);
         const urlParams = new URLSearchParams(window.location.search);
    const hideImport = computed(() => urlParams.has("hideImport"));

        const systemFields = {
            name: "Nombre",
            email: "Email",
            phone: "Teléfono"
        };
        const isAllColumnsMapped = computed(() => {
            return Object.keys(systemFields).every(fieldKey => columnMapping[fieldKey]);
        });
		var searchTimeout;

		const searchParams = new URLSearchParams(new URL(document.URL).search);
		const searchId = searchParams.get('id');

		const state = reactive({
			collaborations: [],
            extraCompanies: [],
			searchResults: [],
			searchCollaborator: null,
			selectedResult: {},
			noResults: false,
			attachToCollaborations: [],
			error: "",
		});


		onMounted(() => {
			getCollaborations();
            getExtraCompanies();
			if (searchId) findCollaborator();
		});

		watch(() => state.searchCollaborator, (input, prevInput) => {
			if(state.searchCollaborator == ""){
				state.searchResults = [];
				state.selectedResult = {};
				state.attachToCollaborations = [];
				state.error = "";
			}
		})

		/**
		 * Funció que executa la petició per obtenir colaboradors (users) que el seu nom d'empresa coincideixi amb l'input introduït (state.searchCollaborator)
		 */
		function searchCollaborators(){
			if(state.searchCollaborator != ""){
				clearTimeout(searchTimeout);
				searchTimeout = setTimeout(() => {
					axios.get(props.searchUrl, {
						params: {
							input: state.searchCollaborator,
						}
					})
					.then((response) => {
						state.searchResults = response.data;
						state.noResults = !state.searchResults.length;
					}).catch((error)=>{
						console.log(error);
					});
				}, SEARCH_TIMEOUT);
			}
		}

        function handleFileUpload(event) {
            selectedFile.value = event.target.files[0];
        }

        async function importExcelFile() {
            if (!selectedFile.value) {
                alert("Por favor, selecciona un archivo.");
                return;
            }

            const formData = new FormData();
            formData.append("file", selectedFile.value);

            try {
                const response = await axios.post("/import-extra-contacts", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });

                getExtraCompanies(); // Recargar la lista de empresas extra
                showImportModal.value = false;
                selectedFile.value = null;
            } catch (error) {
                console.error("Error al importar el archivo:", error);
                alert("Hubo un error al importar el archivo.");
            }
        }

        async function previewExcelData() {
      if (!selectedFile.value) {
        alert("Por favor, selecciona un archivo.");
        return;
      }
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheet = workbook.Sheets[workbook.SheetNames[0]];
        const rows = XLSX.utils.sheet_to_json(sheet, { header: 1 });
        excelHeaders.value = rows[0];
        excelData.value = rows.slice(1);
        previewRows.value = rows.slice(1, 6); // Mostrar solo las primeras 5 filas
        showImportModal.value = false;
        showMappingModal.value = true;
      };
      reader.readAsArrayBuffer(selectedFile.value);
    }

    function isValidEmail(email) {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(email);
    }

    function importMappedData() {
  console.log("Ejecutando importMappedData...");

  mappedDataPreview.value = excelData.value.map(row => {
    let obj = {};
    Object.keys(systemFields).forEach(fieldKey => {
      const selectedColumn = columnMapping[fieldKey];
      if (selectedColumn) {
        const columnIndex = excelHeaders.value.indexOf(selectedColumn);
        if (columnIndex !== -1) {
          obj[fieldKey] = row[columnIndex];
        }
      }
    });
    return obj;
  });

  const invalidEmails = mappedDataPreview.value
    .filter(item => item.email && !isValidEmail(item.email))
    .map(item => item.email);

  if (invalidEmails.length > 0) {
    alert("Los siguientes correos son inválidos:\n" + invalidEmails.join("\n") + "\nRevise los datos antes de continuar.");
    return; // No permitir continuar si hay correos inválidos
  }

  console.log("Datos mapeados:", mappedDataPreview.value);

  // Mostrar modal de confirmación
  showConfirmImportModal.value = true;
  console.log("Estado del modal:", showConfirmImportModal.value);
}

    async function confirmAndImportData() {
        try {
            await axios.post("/import-extra-contacts", { data: mappedDataPreview.value });
            showConfirmImportModal.value = false;
            showMappingModal.value = false;
            window.location.reload();
        } catch (error) {
            console.error("Error al importar los datos:", error);
            alert("Hubo un error al importar los datos.");
        }
    }



        function openDeleteModal(company) {
            console.log(company);
            companyToDelete.value = company;
            showDeleteModal.value = true;
        }

        async function confirmDeleteExtraCompany() {
            if (!companyToDelete.value) return;
            console.log(companyToDelete);
            try {
                await axios.put(`/delete-extra-contact/${companyToDelete.value.id}`);
                state.extraCompanies = state.extraCompanies.filter(c => c.id !== companyToDelete.value.id);
            } catch (error) {
                console.error("Error al eliminar empresa:", error.response?.data || error.message);
            } finally {
                showDeleteModal.value = false;
                companyToDelete.value = null;
            }
        }

        async function getExtraCompanies() {
            try {
                const response = await axios.get("/get-extra-contacts");
                state.extraCompanies = response.data.data;
            } catch (error) {
                console.error("Error al obtener empresas extra:", error.response?.data || error.message);
            }
        }

		/**
		 * Funció que executa la petició per obtenir colaboradors (users) que el seu nom d'empresa coincideixi amb l'input introduït (state.searchCollaborator)
		 */
		function findCollaborator(){
			axios.get(props.searchUrl, {
				params: {
					user_id: searchId,
				}
			})
			.then(async (response) => {
				state.noResults = false;
				state.selectedResult = response.data;
				$.fancybox.open({
					src: '#add-collaborator',
					modal: false,
					opts : {
						afterShow : async(instance, current) => {
							await nextTick();
							searchCollaboratorsInput.value.blur();
						}
					}
				});
			}).catch((error)=>{
				alert('No se ha encontrado la empresa solicitada');
			});
		}

        async function saveExtraCompany() {
            try {
                const response = await axios.post("/extra-contacts", {
                name: extraCompany.name,
                email: extraCompany.email,
                phone: extraCompany.phone
                });

                state.extraCompanies.push(response.data.data);
                closeModal();
            } catch (error) {
                console.error("Error al guardar el contacto extra:", error.response?.data || error.message);
            }
        }

        function editExtraCompany(company) {
            isEditing.value = true;
            extraCompany.id = company.id;
            extraCompany.name = company.name;
            extraCompany.email = company.email;
            extraCompany.phone = company.phone;
            showExtraCompanyModal.value = true;

            console.log("Modo edición activado:", isEditing.value);
            console.log("Datos de la empresa a editar:", extraCompany);
        }

        async function updateExtraCompany() {
            try {
                await axios.put(`/extra-contacts/${extraCompany.id}`, extraCompany);
                const index = state.extraCompanies.findIndex(c => c.id === extraCompany.id);
                if (index !== -1) state.extraCompanies[index] = { ...extraCompany };
                closeModal();
            } catch (error) {
                console.error("Error al actualizar la empresa extra:", error.response?.data || error.message);
            }
        }

        async function deleteExtraCompany(id) {
            if (!confirm("¿Estás seguro de eliminar esta empresa extra?")) return;
            try {
                await axios.delete(`/extra-contacts/${id}`);
                state.extraCompanies = state.extraCompanies.filter(c => c.id !== id);
            } catch (error) {
                console.error("Error al eliminar la empresa extra:", error.response?.data || error.message);
            }
        }

        function closeModal() {
        showExtraCompanyModal.value = false;
        isEditing.value = false;
        extraCompany.id = null;
        extraCompany.name = "";
        extraCompany.email = "";
        extraCompany.phone = "";
        }

		/**
		 * @param collaborator Object
		 * @param event Click Event
		 * Funció per assignar la classe 'active' al resultat de la cerca que s'hagi fet click
		 */
		function selectCollaborator(collaborator, event){
			state.selectedResult = collaborator;
			var children = collaboratorsResults.value.children;

			if(event.target.classList.contains('active')){
				state.selectedResult = {};
				state.attachToCollaborations = [];
				state.error = "";
				event.target.classList.remove('active');
			}else{
				for (let i = 0; i < children.length; i++) {
					children[i].classList.remove('active');
				}
				event.target.classList.add('active');
			}
		}

		/**
		 * @param collaborationId Object
		 * Funció que guarda les colaboracions on més tard s'afegirà el colaborador seleccionat
		 */
		function pushToCollaborations(collaborationId){
			if(state.attachToCollaborations.includes(collaborationId)){
				let index = state.attachToCollaborations.indexOf(collaborationId);
				state.attachToCollaborations.splice(index, 1);
			}else{
				state.attachToCollaborations.push(collaborationId);
			}
		}

		/**
		 * Funció que executa la petició per emmagatzemar el colaborador seleccionat (state.selectedResult) a les colaboracions seleccionades (state.attachToCollaborations)
		 */
		function attachCollaborator(){
			if(state.selectedResult?.id && state.attachToCollaborations.length){
				axios.post('/colaboraciones/attach', {
					collaboratorId: state.selectedResult.id,
					collaborations: state.attachToCollaborations
				})
				.then(function (response) {
					getCollaborations();
					state.searchCollaborator = "";
					state.selectedResult = {};
					state.attachToCollaborations = [];
					state.error = "";
					$.fancybox.close();
				})
				.catch(function (error) {
					state.error = error.response.data.message;
				})
				.then(function () {
					addCollaborationBtn.value.disabled = false;
				});
			}
		}

		/**
		 * Funció que executa la petició per obtenir totes les colaboracions de l'usuari
		 */
		function getCollaborations(){
			axios.get('/colaboraciones/get')
			.then(function (response) {
				state.collaborations = response.data;
			})
			.catch(function (error) {
				alert('Ha ocurrido un error');
			});
		}

		/**
		 * Funció que executa la petició per emmagatzemar una nova colaboració
		 */
		function storeCollaboration(){
			var name = addCollaboration.value.value;
			if(name!=""){
				addCollaborationBtn.value.disabled = true;
				axios.post('/colaboraciones/store', {
					name: name,
					collaborationId: -1
				})
				.then(function (response) {
					state.collaborations.push(response.data.collaboration);
					addCollaboration.value.value = "";
					$.fancybox.close();
				})
				.catch(function (error) {
					alert('Ha ocurrido un error');
				})
				.then(function () {
					addCollaborationBtn.value.disabled = false;
				});
			}
		}

		/**
		 * @param collaborationId Number
		 * Funció que executa la petició per eliminar una colaboració
		 */
		function deleteCollaboration(collaborationId){
			axios.delete('/colaboraciones/destroy/' + collaborationId)
			.then(function (response) {
				// handle success
				getCollaborations();
				state.collaborations = response.data;
			})
			.catch(function (error) {
				// handle error
				alert('Ha ocurrido un error');
			})
			.then(function () {
				// always executed
			});
		}

        /**
         * Función para añadir una empresa extra manualmente
         */
        function addExtraCompany() {
            alert("Función para añadir empresa extra aún no implementada");
            // Aquí puedes abrir un modal o redirigir a un formulario de creación
        }

        /**
         * Función para importar empresas desde un archivo Excel
         */

    return {
        confirmAndImportData,
      showMappingModal,
      previewExcelData,
      importMappedData,
      excelHeaders,
      excelData,
      columnMapping,
      systemFields,
      state,
      extraCompany,
      showDeleteModal,
      companyToDelete,
      showExtraCompanyModal,
      showImportModal,
      openDeleteModal,
      saveExtraCompany,
      editExtraCompany,
        updateExtraCompany,
        deleteExtraCompany,
        confirmDeleteExtraCompany,
      closeModal,
      isEditing,
        addCollaboration,
        addCollaborationBtn,
        collaboratorsResults,
        storeFancybox,
        searchCollaboratorsInput,
        getCollaborations,
        searchCollaborators,
        selectCollaborator,
        pushToCollaborations,
        storeCollaboration,
        deleteCollaboration,
        attachCollaborator,
        addExtraCompany,
        handleFileUpload,
        importExcelFile,
        previewRows,
        isAllColumnsMapped,
        hideImport,
        showConfirmImportModal,
      mappedDataPreview,

    };
  },
};
</script>

<style>
.styled-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 18px;
  text-align: left;
}

.styled-table th, .styled-table td {
  padding: 12px;
  border: 1px solid #ddd;
}

.styled-table th {
  background-color: #f4f4f4;
  font-weight: bold;
}

.styled2-table {

  border-collapse: collapse;
  margin: 20px 0;
  font-size: 18px;
  text-align: left;
}

.styled2-table th, .styled-table td {
  padding: 12px;
  border: 1px solid #ddd;
}

.styled2-table th {
  background-color: #f4f4f4;
  font-weight: bold;
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal {
  background: white;
  padding: 20px;
  border-radius: 5px;
  max-width: 90%; /* Máximo 90% del ancho de la pantalla */
  width: auto;
  display: inline-block;
}
.modal-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.button {
  padding: 8px 15px;
  cursor: pointer;
}

.company-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
    border-bottom: 1px solid #ddd;
}

.company-info {
    flex: 1;
}

.company-actions {
    display: flex;
    gap: 8px;
}

.disabled-company {
    color: gray;
    background-color: #f3f3f3;
}


</style>
